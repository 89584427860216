import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { FormikProvider, useFormik } from 'formik';

import AppInput from 'shared/AppInput';
import AppToast from 'shared/AppToast';
import GatewayLayout from './components/GatewayLayout';
import InstructionLine from './components/InstructionLine';
import PaymentInstallmentsForm from './components/PaymentInstallmentsForm';

import axios from 'utils/axios-rails';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  checkoutId: PropTypes.string.isRequired,
  cancelURL: PropTypes.string.isRequired,

  displayAmount: PropTypes.string.isRequired,

  credixInstallments: PropTypes.array.isRequired,
  credomaticInstallments: PropTypes.array.isRequired,
  daviviendaInstallments: PropTypes.array.isRequired,
  currentYear: PropTypes.number.isRequired,

  credomatic: PropTypes.shape({ // when 3ds gets implemented this will be required
    keyId: PropTypes.string.isRequired,  // like a public key, used for hash
    timestamp: PropTypes.string.isRequired, // used for hash
    hash: PropTypes.string.isRequired, // to validate the data in and back
    submitURL: PropTypes.string.isRequired, // cardinal URL
    redirectURL: PropTypes.string.isRequired, // back to App redirect

    amount: PropTypes.string.isRequired,

    initialError: PropTypes.string,

    allowDavivienda: PropTypes.bool,
  }),
}

const defaultProps = {
  allowDavivienda: false
}

const CuotasGatewayForm = (props) => {
  const layoutRef = useRef(null);
  const credomaticFormRef = useRef(null);

  // Temporally for both, later will only work for credix, handle processing on backend and get response inmediatly
  const handleAjaxProcess = async (values) => {
    const response = await axios.post(props.submitURL, {
      cuotas: {
        ...values,
        checkout_id: props.checkoutId,
      }
    });
    const { data } = response;
    if (data.success) {
      layoutRef.current.setToastMessage(data.message);
      window.location.href = data.redirect_url; // Redirect to shopify with response params
    }
    else {
      formik.setErrors(data.errors);
      layoutRef.current.setToastMessage(data.message);
    }
  }

  const handleSubmit = async (values) => {
    try {
      if (!!props.credomatic.keyId && !values.credix_payment_id && !values.davivienda_payment_id) { // later change to if(!values.credix_payment_id)
        // Values are mirrored on hidden REST form, just call submit on form
        const form = credomaticFormRef.current;
        form.submit();
      }
      else { // credomatic legacy and credix
        await handleAjaxProcess(values);
      }
    } catch (error) { // 500 or JS errors
      console.log("ErrorC", error)
      layoutRef.current.setToastMessage("Ocurrio un error de conexión con el servicio.");
    }
  }

  const formik = useFormik({
    initialValues: {
      cname: "",
      clastname: "",
      // cmonth: "1",
      // cyear: props.currentYear,
      ccexp: "",
      cvv: "",
      ccnumber: "",

      credomatic_payment_id: "",

      credix_payment_id: "",
      davivienda_payment_id: "",
      credix_pin: "",
      disable: false
    },
    onSubmit: handleSubmit,
  });

  const displayAmount = props.displayAmount;

  useEffect(() => {
    if (!!props.initialError) {
      layoutRef.current.setToastMessage(props.initialError);
    }
  }, []);

  // Bin validation for Davivienda
  useEffect(() => {
    let davivienda_authorized = formik.values.ccnumber.slice(0, 9) != "4984 6548"

    if ((formik.values.davivienda_payment_id === "18" && davivienda_authorized) ||
      (formik.values.davivienda_payment_id === "24" && davivienda_authorized)) {
      formik.setFieldValue("disable", true)
    } else {
      formik.setFieldValue("disable", false)
    }
  }, [formik.values.davivienda_payment_id, formik.values.ccnumber])

  return (
    <div>
      <GatewayLayout ref={layoutRef}
        disabled={formik.values.disable}
        loading={formik.isSubmitting}
        cancelURL={props.cancelURL} onSubmit={formik.handleSubmit}
        totalAmount={displayAmount}
        displayCuotasBadges
      >
        {/* Instructions */}
        <h2 className="fs18 mb12"><strong>¿COMO DESEA PAGAR?</strong></h2>
        <PaymentInstallmentsForm
          values={formik.values}
          errors={formik.errors}
          handleChange={formik.handleChange}
          setFieldValue={formik.setFieldValue}
          resetForm={formik.resetForm}
          credomaticInstallments={props.credomaticInstallments}
          credixInstallments={props.credixInstallments}
          daviviendaInstallments={props.daviviendaInstallments}
          allowDavivienda={props.allowDavivienda}
        />
      </GatewayLayout>

      {/* To use 3DS with credomatic (ajax isn;t supported) */}
      {props.credomatic.keyId &&
        <div className='hidden_imp'>
          <form id="credomatic-3ds-form" ref={credomaticFormRef} action={props.credomatic.submitURL} method="post">
            {/* "immutable" types counter-validated with hash */}
            {/* Redirect includes processor_name param to be able to get on redirect the processor_id used AS bac doesn't return it (only for display purposes as processing is done with processor_id sent) */}
            <input type="hidden" name="redirect" value={`${props.credomatic.redirectURL}&processor_name=${formik.values.credomatic_payment_id}`} />
            <input type="hidden" name="key_id" value={props.credomatic.keyId} />
            <input type="hidden" name="time" value={props.credomatic.timestamp} />
            <input type="hidden" name="hash" value={props.credomatic.hash} />
            {/* From shopify checkout  */}
            <input type="hidden" name="amount" value={props.credomatic.amount} />
            <input type="hidden" name="orderid" value={props.checkoutId} />

            {/* Dynamic values from form */}
            <input type="hidden" name="processor_id" value={formik.values.credomatic_payment_id} />
            <input type="hidden" name="cname" value={formik.values.cname} />
            <input type="hidden" name="clastname" value={formik.values.clastname} />
            <input type="hidden" name="ccexp" value={formik.values.ccexp} />
            <input type="hidden" name="cvv" value={formik.values.cvv} />
            <input type="hidden" name="ccnumber" value={formik.values.ccnumber} />
          </form>
        </div>
      }
    </div>
  )
};



CuotasGatewayForm.propTypes = propTypes;
CuotasGatewayForm.defaultProps = defaultProps;

export default CuotasGatewayForm;
import React, { useState, useEffect } from 'react';

import AppNotification from 'components/shared/AppNotification';

export {
  AppNotification,
};

export default function useMuiNotification(initialSeverity = 'info') {
  const [notification, setNotification] = useState({
    open: false,
    message: null,
    severity: initialSeverity, // "info", "success", "warning", "error" from MUI Alert
  });

  const handleClose = () => {
    setNotification({ ...notification, open: false });
  }

  /**
   * Set message and optionally severity for notification. Severity is only relevant if rendering Snackbar with nested Alert
   * @param {String} message - Content of the notification
   * @param {("info"|"success"|"warning"|"error")} [severity=initialSeverity] - Severity of the notification. Possible values are "info", "success", "warning", "error" from MUI Alert
   */
  const sendNotification = ({ message, severity = initialSeverity }) => {
    setNotification({
      open: true,
      message,
      severity,
    })
  }

  // Tuple with [notificationActions, snackbarProps]
  const snackbarProps = { // as named on MUI Snackbar
    handleClose,
    open: notification.open,
    message: notification.message,
    severity: notification.severity,
  };

  return [sendNotification, snackbarProps];
}

// Use like this:
{/*
  const [sendNotification, snackProps] = useMuiNotification(); 
  <Snackbar
    autoHideDuration={6000}
    {...snackProps}
  /> 
  OR
  const [sendNotification, { message, severity, ...snackProps }] = useMuiNotification('error');
  <Snackbar autoHideDuration={6000} {...snackProps}>
    <Alert onClose={snackProps.handleClose} severity={ severity }>
      { message }
    </Alert>
  </Snackbar>
*/}

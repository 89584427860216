// New way from 2024 onward to manage UI queries inside the App
import { ApolloClient, InMemoryCache, gql, from, HttpLink } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
export * from './queries/index';
export * from './queries/contentManagerQueries';
export {
  gql
}
const element = document.querySelector('[data-admin-token]');
const adminToken = element ? element.getAttribute('data-admin-token') : null;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({ 
  uri: '/admin/graphql',
  headers: {
    'Authentication': `Bearer ${adminToken}`
  }
});

const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
});

// Parse errors from GraphQL userError to formik errors
export const userErrorsToFormik = (errors) => {
  const fieldErrors = {};
  for (const error of errors) {
    fieldErrors[error.field] = error.message;
  }

  return fieldErrors;
};

export default client;
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { renderAccessorValue } from 'utils';

const propTypes = {
  items: PropTypes.array.isRequired,
  columnsData: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    header: PropTypes.string.isRequired,
    accessor: PropTypes.oneOfType([ PropTypes.string, PropTypes.func, ]).isRequired
  })).isRequired,

  emptyState: PropTypes.node,
  header: PropTypes.node,
  columnUtils: PropTypes.object, // Utils to use on accessors
}

const defaultProps = {
  columnUtils: {},
}

// research filters with react useContext
const AppIndex = (props) => {
  const rowMarkup = props.items.map(item => {
    return (
      <TableRow
        key={ item.id }
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        { props.columnsData.map( (col, index) => (
          <TableCell key={ index }>
            { renderAccessorValue({ record: item, columnInfo: col, utils: props.columnUtils }) }
          </TableCell>
        ))}
      </TableRow>
    )
  });

  const headerMarkup = useMemo( () => {
    return props.columnsData.map( (col, index) => (
      <TableCell key={ index }>{ col.header }</TableCell>
    ))
  }, []);

  return (
    <TableContainer component={Paper}>
      { props.header && props.header }
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            { headerMarkup }
          </TableRow>
        </TableHead>
        <TableBody>
          { rowMarkup }
        </TableBody>
      </Table>
    </TableContainer>
  )
};

AppIndex.propTypes = propTypes;
AppIndex.defaultProps = defaultProps;

export default AppIndex;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import AppInput from 'shared/AppInput';
import LocationDropdowns from './components/LocationDropdowns';
import Form from 'react-bootstrap/Form';

import { useFormik } from 'formik';
import axios from 'utils/axios-rails';

import useMuiNotification, { AppNotification } from 'hooks/useMuiNotification';

const propTypes = {
  application: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    // statusName
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    company_name: PropTypes.string.isRequired,
    national_id: PropTypes.string.isRequired,
    province: PropTypes.string,
    canton: PropTypes.string,
    district: PropTypes.string,
    zip: PropTypes.string,
    address: PropTypes.string,
    sector: PropTypes.string,
    company_department: PropTypes.string,
    referral: PropTypes.string,
    // sectorName
    employees_count: PropTypes.string,
    // employeesCountName
    brightpearl_contact_owner_id: PropTypes.number,
    shopify_id: PropTypes.string,
    bright_pearl_id: PropTypes.string,
  }).isRequired,

  editable: PropTypes.bool.isRequired,
  contact_owner: PropTypes.string, // from API
  contact_owners: PropTypes.array.isRequired,

  temporalTranslations: PropTypes.shape({
    createdAtHuman: PropTypes.string.isRequired,
    statusHuman: PropTypes.string.isRequired,
    companyDepartmentHuman: PropTypes.string, // Legacy records don't have this field
    referralHuman: PropTypes.string
  }).isRequired,

  indexURL: PropTypes.string.isRequired,
  shopifyURL: PropTypes.string,
  approveURL: PropTypes.string.isRequired,
  rejectURL: PropTypes.string.isRequired,
  updateURL: PropTypes.string.isRequired,

  approvalMessage: PropTypes.string,
}

const defaultProps = {

}

const BusinessApplicationShow = (props) => {
  const [sendNotification, notificationProps] = useMuiNotification();

  const application = props.application;
  const temporalTranslations = props.temporalTranslations;

  const shopify_published = !!application.shopify_id;
  const bp_published = !!application.bright_pearl_id;

  const applicationName = `${application.first_name} (${application.last_name})`;

  useEffect(() => {
    if(!!props.approvalMessage){
      sendNotification({ message: props.approvalMessage, severity: "info" });
    }
  }, []);

  const handleSave = async (values) => {
    console.log("Sending save request", values);
    try {
      const response = await axios.put(props.updateURL, {
        business_application: values
      });
      sendNotification({ message: response.data.message, severity: "success" });
    } 
    catch (error) {
      sendNotification({ message: "Ocurrio un error.", severity: "error" });
      if(error.response && error.response.data){
        formik.setErrors(error.response.data);
      }
    }
    formik.setSubmitting(false);
  }

  const formik = useFormik({
    initialValues: props.application,
    onSubmit: handleSave
  });

  const csrfToken = document.querySelector("meta[name=csrf-token]").content;
  const values = formik.values;


  // const validateLocation = (event) => {
  //   if(formik.values.province == 'province' || formik.values.canton == 'canton' || formik.values.district == 'district' ) {
  //     alert("Por favor, llena todos los campos antes de enviar el formulario");
  //     event.preventDefault();
  //   }
  // }

  return (
    <div className="container card">
      <AppNotification
          {...notificationProps}
        />
      <div className="card-body">
        <div className="flex">
          <a href={props.indexURL}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"/>
            </svg>
          </a>
          <h3 className="ml12 mb0">
            Aplicación de: <strong>{ application.name }</strong>
            <br/>
          </h3>
        </div>
        <small className="text-muted">Recibida el { temporalTranslations.createdAtHuman }</small>
        <br/>
        <div className="badge badge-info mr8 }">{ temporalTranslations.statusHuman }</div>
        <div className="badge badge-info }">{ temporalTranslations.countryHuman }</div>
      
        <dl className="row mt20">
          <dt className="col-sm-3">{ "Nombre" }</dt>
          <dd className="col-sm-9">{ application.name }</dd>
          <dd className="col-sm-12 border-bottom"></dd>
          
          <dt className="col-sm-3">{ "Correo Electrónico" }</dt>
          <dd className="col-sm-9">{ application.email }</dd>
          <dd className="col-sm-12 border-bottom"></dd>
          <dt className="col-sm-3">{ "Teléfono" }</dt>

          <dd className="col-sm-3 input-group" id="container_action">
            {/* Editable */}
            { props.editable &&
              <AppInput
                name="phone"
                value={ values.phone }
                onChange={ formik.handleChange }
                error={ formik.errors.phone }
              />
            }
            {/* Or just display value */}
            { !props.editable && application.phone }
          </dd>

          <dd className="col-sm-12 border-bottom"></dd>
      
          <dt className="col-sm-3">{ "Nombre de empresa" }</dt>
          <dd className="col-sm-9">{ application.company_name }</dd>
          <dd className="col-sm-12 border-bottom"></dd>
      
          <dt className="col-sm-3">{ "Cédula jurídica" }</dt>
          <dd className="col-sm-9">{ application.national_id }</dd>
          <dd className="col-sm-12 border-bottom"></dd>
          
          <dt className="col-sm-3">{ "Departamento" }</dt>
          <dd className="col-sm-9">{ temporalTranslations.companyDepartmentHuman }</dd>
          <dd className="col-sm-12 border-bottom"></dd>

          {/* For now display the fields like if they were in a form (not elegant) */}
          { props.editable && 
            <>
              <LocationDropdowns
                onChange={ formik.setFieldValue }
                errors={ formik.errors }
                locationsDataset={ props.locationsDataset }
                initialValues={{
                  province: values.province,
                  canton: values.canton,
                  district: values.district,
                }}
              />
              <dd className="col-sm-12 border-bottom"></dd>
            </>
          }

          {/* Else display just values */}
          { !props.editable &&
            <>
              <dt className="col-sm-3">{ "Provincia" }</dt>
              <dd className="col-sm-9">{ application.province }</dd>
              <dd className="col-sm-12 border-bottom"></dd>

              <dt className="col-sm-3">{ "Cantón" }</dt>
              <dd className="col-sm-9">{ application.canton }</dd>
              <dd className="col-sm-12 border-bottom"></dd>
              
              <dt className="col-sm-3">{ "Distrito" }</dt>
              <dd className="col-sm-9">{ application.district }</dd>
              <dd className="col-sm-12 border-bottom"></dd>
            </>
          }

          
          <dt className="col-sm-3">{ "Zip" }</dt>
          <dd className="col-sm-9">{ values.zip }</dd>
          <dd className="col-sm-12 border-bottom"></dd>
      
          <dt className="col-sm-3">{ "Direccion de entrega" }</dt>
          
          <dd className="col-sm-9 input-group" id="container_action">
            { props.editable &&
              <AppInput
                name="address"
                value={ values.address }
                onChange={ formik.handleChange }
                error={ formik.errors.address }
              />
            }
            { !props.editable && application.address }
          </dd>          
         
          <dd className="col-sm-12 border-bottom"></dd>

          <dt className="col-sm-3">{ "Sector" }</dt>
          <dd className="col-sm-9">{ temporalTranslations.sectorHuman }</dd>
          <dd className="col-sm-12 border-bottom"></dd>

          <dt className="col-sm-3">{ "Cantidad de empleados" }</dt>
          <dd className="col-sm-9">{ temporalTranslations.employeesCountHuman }</dd>
          <dd className="col-sm-12 border-bottom"></dd>

          {/* Only editable before approving/rejecting */}
          <dt className="col-sm-3">{ "Asignar a" }</dt>
          <dd className="col-sm-9">
            { props.pending && (
              <>
                <Form.Control value={ values.brightpearl_contact_owner_id } onChange={ formik.handleChange } name="brightpearl_contact_owner_id"  as="select" custom>
                  { props.contact_owners.map( (option, index) => 
                    <option key={ option.value } value={ option.value }>{ option.label }</option>
                  )}
                </Form.Control>
              </>
            )}

            { !props.pending && props.contact_owner }
          </dd>
{/* aca deberia estar el dropdown de referral           */}
              <dt className="col-sm-3">{ "Canal por el cual conoció a Unimart Business " }</dt>
              <dd className="col-sm-9">

              <Form.Control value={ values.referral} onChange={formik.handleChange} name="referral" as="select" custom>
                {props.temporalTranslations.referralOptions.map(option => (
                  <option key={option.value} value={option.value}> {option.label} </option>
                ))}
              </Form.Control>
              </dd>


{/* arriba deberia estar el dropdown de referral   */}
          
          {/* Shopify and Brightpearl indicators */}
          { application.status == "accepted" &&
            <>
              <dd className="col-sm-12 border-bottom"></dd>
              {/* Shopify */}
              <dt className="col-sm-3">{ "Shopify ID" }</dt>
              <dd className="col-sm-9">
                <div className={`badge badge-${ shopify_published ? 'success' : 'secondary' }`}>
                  { shopify_published ? "Creado" : "Pendiente" }
                </div>
                { shopify_published && 
                  <a target="_blank" href={ props.shopify_url } className="btn btn-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                  </svg>  
                  </a>
                }
              </dd>
              <dd className="col-sm-12 border-bottom"></dd>
              <dt className="col-sm-3">{ "Brightpearl ID" }</dt>
              <dd className="col-sm-9">
                {/* Brightpearl */}
                <div className={ `badge badge-${ bp_published ? 'success' : 'secondary' }` }>
                  { bp_published ? "Creado" : "Pendiente" }
                </div>
              </dd>
            </>
          }
        </dl>

        <div className="flex justify-end">
          {/* Save form changes */}
          { props.editable &&
            <button type="submit" onClick={ formik.handleSubmit } className="btn btn-info mr20">Guardar</button>
          }
        
          {/* Approve/Reject */}
          { props.pending  &&
            <>
              <form action={ props.approveURL } method="POST" onSubmit={ (event) => validate_location(event)} >
                <input type="hidden" name="authenticity_token" value={ csrfToken } />
                <input type="submit" data-confirm="¿Está seguro que desea aprobar la solicitud?" value="Aprobar" className="btn btn-primary mr20" />
              </form>
             
              <form action={ props.rejectURL } method="POST"  >
                <input type="hidden" name="authenticity_token" value={ csrfToken } />
                <input type="submit" data-confirm="¿Está seguro que desea rechazar la solicitud?" value="Rechazar" className="btn btn-danger" />
              </form>
            </>
          }
        </div>
      </div>
    </div>
  )
};

BusinessApplicationShow.propTypes = propTypes;
BusinessApplicationShow.defaultProps = defaultProps;

export default BusinessApplicationShow;
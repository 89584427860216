import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
  Card, CardContent, 
  Typography, Button, Chip, Divider,
  ListItem, ListItemText, ListItemAvatar, Avatar,
} from '@mui/material';

import axios from 'utils/axios-rails';
import Page from 'shared/AppPage';
import UploadButton from 'components/shared/form/UploadButton';

import useMuiNotification, { AppNotification } from 'hooks/useMuiNotification';
import DescriptionIcon from '@mui/icons-material/Description';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

const InvoiceBulkInspection = (props) => {
  const [loading, setLoading] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [sendNotification, notificationProps] = useMuiNotification();
  const [inspectionData, setInspectionData] = useState(null);

  const submitFile = async () => {
    setLoading(true);
    let success = false;
    try {
      const formData = new FormData;
      formData.append("file", importFile); // from state
      const { data} = await axios.post(props.submitURL, formData);

      console.log("DD", data)

      if(data.success){
        success = true;
        sendNotification({ message: "Carga exitosa", severity: "success" });
        setInspectionData(data.result);
      }
      else{
        sendNotification({ message: data.errors, severity: "error" });
      }
      
    } catch (error) {
      console.log("EEE", error);
      sendNotification({ message: "Error de conexión", severity: "error" });
    }
    
    setLoading(false);
    return success;
  };

  return (
    <Page
      title='Revision masiva de facturas'
    >
      <Card>
        <AppNotification
          {...notificationProps}
        />
        <CardContent>
          <UploadButton 
            onUpload={ setImportFile }
            helpText="Solo se aceptan archivos .xlsx y .xls"
          />

          <Button 
            className='mt12'
            fullWidth variant="contained" 
            color='primary' 
            onClick={ submitFile } 
            disabled={ loading || !importFile }>
            Cargar
          </Button>

          {/* Results */}
          { inspectionData &&
          <div>
            <Divider className='mv12'/>

            <Typography variant='h5'>
              Facturas encontradas: { inspectionData.existing_ids.length }
            </Typography>

            { inspectionData.existing_ids.map((id) => (
              <Chip key={id} className="mr4" label={id} />
            ))}

            <Divider className='mv12' />

            <Typography variant="h5">
              Facturas faltantes: { inspectionData.missing_ids.length }
            </Typography>

            { inspectionData.missing_ids.map((id) => (
              <Chip key={id} className="mr4" label={id} />
            ))}

            <Divider className='mv12' />

            <Typography variant="h5">
              Registros
            </Typography>
            
            { inspectionData.invoices.map((invoice) => (
              <ListItem key={ invoice.id }>
                <ListItemAvatar>
                  <Avatar>
                    <DescriptionIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                    <Typography variant="h6">
                      { invoice.bright_pearl_order_id }
                    </Typography>
                    <Typography variant="body2">
                      { invoice.status_human }
                    </Typography>
                    </>
                  }
                  secondary={ invoice.issued_at }
                />
              </ListItem>
            ))}
          </div>
          }
        </CardContent>
      </Card>
    </Page>
  )
};

InvoiceBulkInspection.propTypes = propTypes;
InvoiceBulkInspection.defaultProps = defaultProps;

export default InvoiceBulkInspection;
import { Text } from 'slate';
import escapeHtml from 'escape-html';

import { ELEMENT_TYPES, LEAF_TYPES } from './actions';

// Slate HTML serializer. Pick slate nodes data and convert them to HTML.

// Return string of HTML elements based on the node type.
const serializeNode = (node) => {
  // node provided is a child "leaf", which contains the text and marks
  // i.e. result: <span><strong><em>Some text</em></strong></span> from node like { text: 'Some text', bold: true, italic: true }
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if (node[LEAF_TYPES.BOLD]) {
      string = `<strong>${string}</strong>`
    }

    if (node[LEAF_TYPES.ITALIC]) {
      string = `<em>${string}</em>`
    }

    if (node[LEAF_TYPES.UNDERLINE]) {
      string = `<u>${string}</u>`
    }

    if (node[LEAF_TYPES.LINK]) { // top wrapper for leafs
      string = `<a href="${escapeHtml(node[LEAF_TYPES.LINK])}">${string}</a>`
    }

    string = `<span>${string}</span>` // Wrap the text in a span element at the end always
    return string; // HTML string
  }

  // Node provided is a block element (parent of leafs).
  // serialize each child node (leaf) and join them together to render them INSIDE the block element depending on the block type.
  const children = node.children.map(n => serializeNode(n)).join('')

  // Based on the node block type, render the block wrapper with the children inside.
  let htmlStyle = `_STYLE_`;
  if (node.align) {
    htmlStyle = htmlStyle.replace('_STYLE_', `text-align: ${node.align};_STYLE_`)
  }
  // Add more styles here if needed
  htmlStyle = htmlStyle.replace('_STYLE_', ''); // Remove the last _STYLE_ placeholder

  const tagStyling = htmlStyle ? ` style="${htmlStyle}"` : '';
  const tagBase = `<TAG_STYLE_>${children}</TAG>`.replace(/_STYLE_/g, tagStyling); // `<TAG style="text-align: center;">${children}</TAG>`
  switch (node.type) {
    case ELEMENT_TYPES.BLOCK_QUOTE:
      return tagBase.replace(/TAG/g, 'blockquote'); // `<blockquote>${children}</blockquote>`
    case ELEMENT_TYPES.BULLET_LIST:
      return tagBase.replace(/TAG/g, 'ul'); // `<ul>${children}</ul>`
    case ELEMENT_TYPES.NUMBERED_LIST:
      return tagBase.replace(/TAG/g, 'ol'); // `<ol>${children}</ol>`
    case ELEMENT_TYPES.LIST_ITEM:
      return tagBase.replace(/TAG/g, 'li'); // `<li>${children}</li>`
    case ELEMENT_TYPES.HEADING_ONE:
      return tagBase.replace(/TAG/g, 'h1'); // `<h1>${children}</h1>`
    case ELEMENT_TYPES.HEADING_TWO:
      return tagBase.replace(/TAG/g, 'h2'); // `<h2>${children}</h2>`
    default:
      return tagBase.replace(/TAG/g, 'p'); // `<p>${children}</p>`
  }
}

export const serializeToHtml = (nodes) => {
  return nodes.map(n => serializeNode(n)).join('')
};


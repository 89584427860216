import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useNode } from '@craftjs/core';

import { FormLabel, Grid, ToggleButton, ToggleButtonGroup, TextField, Typography } from '@mui/material';
import { AlignHorizontalLeft, AlignHorizontalRight } from '@mui/icons-material';

import { useFormikContext } from 'formik';

import PreviewBannerImage from './components/PreviewBannerImage';
import AppAutocomplete from 'components/shared/form/AppAutocompleteApi';
import ImageDropZone from 'components/shared/form/ImageDropzone';
import useImageAppPageAsset from './components/useImageAppPageAsset';

import * as SHAPES from './types';
import * as QUERIES from 'api/apollo-client';

import lodashSet from 'lodash/set';

const propTypes = {
  collection: PropTypes.shape({
    imageUrl: PropTypes.string, // Usually the logo
    resourceHandle: PropTypes.string, // To get the URL to the collection
    resourceId: PropTypes.string, // Shopify ID
    title: PropTypes.string.isRequired,
  }),
}

const defaultProps = {
  collection: {
    imageUrl: null,
    resourceHandle: "",
    resourceId: "",
    title: "",
  },
}

const CollectionWithItems = (props) => {
  const { status } = useFormikContext();

  const { collection, ...builderProps } = props;
  const { connectors: { connect, drag }, } = useNode();

  const [editable, setEditable] = useState(false);

  const isMobile = status.viewport === "MOBILE";

  const handleLinkClick = (e) => { e.preventDefault(); }

  const relativeUrl = `/collections/${collection.resourceHandle}`;
  const itemsCount = isMobile ? 2 : 3; // Items to load from collection (for preview only placeholder)
  const itemGridSize = isMobile ? 6 : 4;

  return (
    <div
      className="user-block user-collection-with-items"
      ref={ref => connect(drag(ref))}
      {...builderProps}
    >
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <div className='item-container'>
            <PreviewBannerImage 
              banner={{
                imageUrl: collection.imageUrl,
                resourceId: collection.resourceHandle,
                resourceUrl: relativeUrl,
              }} 
            />

            <a href={ relativeUrl } className='collection-link' onClick={handleLinkClick}>
              { collection.title }
            </a>
          </div>
        </Grid>

        <Grid item xs={8}>
          <Grid container spacing={3} className='collection-items-container'>
            { [...Array(itemsCount)].map((_, index) => (
              <Grid item xs={itemGridSize} key={index}>
                <div className='collection-item'>
                  <a href="#" onClick={handleLinkClick}>
                    <div className='asset-block-bg-placeholder'></div>
                    <p>
                      Item title placeholder for collection
                    </p>
                  </a>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
};

// Optional text content for banner


// Settings panel for block
const CollectionWithItemsSettings = () => {
  const { actions: {setProp}, collection } = useNode((node) => ({
    collection: node.data.props.collection,
  }));

  const [{ value: imageUrl, loading, errors }, { handleImageChange, handleImageDelete }] = useImageAppPageAsset("collection.imageUrl");

  const handleCollectionSelection = useCallback((value, option) => {
    setProp(props => lodashSet(props, "collection.resourceId", value));
    setProp(props => lodashSet(props, "collection.resourceHandle", option.data.handle));
  }, []);

  const autocompleteVariablesConstructor = useCallback((searchValue) => {
    return { kind: "COLLECTION_TOP", query: searchValue };
  }, []);

  return (
    <>
      <ImageDropZone
        label={ `200x200` }
        onChange={handleImageChange}
        onDelete={handleImageDelete}
        imageUrl={collection.imageUrl}
        loading={loading}
      />

      { errors.map((error, index) => (
        <Typography key={index} variant="caption" color="error" component="p">
          { error.message }
        </Typography>
      )) }
      

      <TextField
        fullWidth
        label="Title"
        disabled={ loading }
        value={collection.title}
        onChange={e => setProp(props => lodashSet(props, "collection.title", e.target.value))}
        variant='outlined'
      />

      <AppAutocomplete
        fullWidth
        label={ "Collection" }
        placeholder='Samsung, Apple, etc.'
        graphqlQuery={ QUERIES.APP_PAGE_SHOPIFY_RESOURCE_SEARCH }
        onSelection={ handleCollectionSelection }
        itemValueAccessor="id"
        itemLabelAccessor="title"
        variablesConstructor={ autocompleteVariablesConstructor}
      />

      <TextField 
        fullWidth
        label="Collection URL"
        disabled
        value={ `/collections/${collection.resourceHandle}` }
      />
    </>
  )
}

CollectionWithItems.craft = {
  props: defaultProps,
  related: {
    name: () => "Carrusel de Colección",
    settings: CollectionWithItemsSettings
  }  
}

CollectionWithItems.propTypes = propTypes;
CollectionWithItems.defaultProps = defaultProps;

export default CollectionWithItems;
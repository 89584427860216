import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMuiNotification } from 'utils';
import AppNotification from 'components/shared/AppNotification';

import { useLazyQuery, gql } from '@apollo/client';
import adminClient from 'api/apollo-client';

import Page from 'shared/AppPage';

import { 
  Card, CardContent, LinearProgress, Typography, 
  Button, Stack, TextField,
  InputAdornment, IconButton,
  Container
} from '@mui/material';

import { ChangeCircle, ContentCopy } from '@mui/icons-material';

import dayjs from 'dayjs';

const propTypes = {

}

const defaultProps = {

}

const GlobalSkuGenerator = (props) => {
  const [sendNotification, notificationProps] = useMuiNotification();

  const [itemCount, setItemCount] = useState(1);
  const [sku, setSku] = useState(null); // For single SKU generation

  const [generateOneSku, { loading: loadingOneSku }] = useLazyQuery(GENERATE_ONE_SKU, {
    client: adminClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSku(data.toolsGlobalSkuGenerator.skus[0]);
    },
  });

  const [generateSkusFile, { loading: loadingFileSkus }] = useLazyQuery(GENERATE_SKUS_FILE, {
    client: adminClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const base64Data = data.toolsGlobalSkuGenerator.fileBase64;
      const link = document.createElement('a');
      const dateTimeText = dayjs().format('DD-MM-YYYY-h_mmA'); // 30-10-2024 12_30PM (already with timezone applied)

      link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + base64Data;
      link.download = `skus-${dateTimeText}.xlsx`; // Set the file name
      document.body.appendChild(link); // Append the link to the body
      link.click(); // Trigger the download
      document.body.removeChild(link); // Remove the link from the DOM
    },
    onError: (error) => {
      console.error("SKUS file error", error);
      sendNotification({ message: 'Error al generar SKU\'s', severity: 'error' });
    }
  });

  const handleSkuGeneration = () => {
    const itemCountValue = Number(itemCount);
    if (itemCountValue === 1) {
      generateOneSku({ variables: { count: itemCountValue } });
    } 
    else {
      generateSkusFile({ variables: { count: itemCountValue } });
    }
  }

  const handleSkuCopy = () => {
    navigator.clipboard.writeText(sku);
    sendNotification({ message: 'SKU copiado al portapapeles', severity: 'success' });
  }

  const handleItemCountChange = (e) => setItemCount(e.target.value);

  const loading = loadingOneSku || loadingFileSkus;

  return (
    <Page>
      <AppNotification {...notificationProps} />

      <Container>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
                SKU Global
            </Typography>

            <Typography color='GrayText'>
              Puede generar SKU's individualmente o en batch, en caso de generar múltiples SKU's, se descargaran en un archivo XLSX.
              <br/>El máximo de SKU's que puede generar en un solo batch es de 200.
            </Typography>

            <form onSubmit={e => e.preventDefault()}>
              <TextField
                label="Cantidad"
                className='mt20'
                sx={{ minWidth: "200px" }}
                variant="outlined"
                type="number"
                inputProps={{ min: 1, max: 200 }}
                value={ itemCount }
                onChange={ handleItemCountChange }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type='submit'
                        color='primary'
                        disabled={ !itemCount || loading }
                        aria-label="submit"
                        onClick={handleSkuGeneration}
                        edge="end"
                      >
                        <ChangeCircle />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </form>

            { !!sku &&
              <div>
                <TextField
                  disabled
                  fullWidth
                  className='mt20'
                  label="SKU"
                  value={sku}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSkuCopy}
                          edge="end"
                        >
                          <ContentCopy />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            }
          </CardContent>
          
        </Card>
        { loading && <LinearProgress color='primary' /> }
      </Container>
    </Page>
  )
};

GlobalSkuGenerator.propTypes = propTypes;
GlobalSkuGenerator.defaultProps = defaultProps;

const GENERATE_ONE_SKU = gql`
  query($count: Int!) {
    toolsGlobalSkuGenerator(count: $count) {
      skus
    }
  }
`;

const GENERATE_SKUS_FILE = gql`
  query($count: Int!) {
    toolsGlobalSkuGenerator(count: $count) {
      fileBase64
    }
  }
`;

export default GlobalSkuGenerator;